import { Capacitor } from '@capacitor/core'

import { useDeliveryType } from '@/hooks/app'
import { useMember } from '@/hooks/user'
import { useMerchant } from '@/hooks/merchant'
import { useSelector } from '@/redux'
import PaymentMethod from '@/constants/paymentMethod'
import config from '@/config'
import paymentGateway from '@/constants/paymentGateway'

function canMakeApplePay () {
  if (!window.location.href.includes('https')) {
    return false
  }
  if (!window.ApplePaySession) {
    return false
  }
  if (!window.ApplePaySession.canMakePayments(config.applePay2c2p.merchantId)) {
    return false
  }
  return true
}

export default function useAcceptedPaymentMethods () {
  const member = useMember()
  const merchant = useMerchant()
  const { isTable } = useDeliveryType()
  const creditCardGateway = useSelector(state => state.app.paymentGateway.creditcard)
  const platform = Capacitor.getPlatform()

  const acceptedPaymentMethods = []

  if (!merchant?.setting) return []

  if (config.env !== 'prod') {
    // 非 production 環境顯示 bypass payment
    acceptedPaymentMethods.push(PaymentMethod.BYPASS)
  }

  if (isTable ? merchant.setting.enableDineInPayMe : merchant.setting.enablePayMe) {
    acceptedPaymentMethods.push(PaymentMethod.PAY_ME)
  }

  acceptedPaymentMethods.push(PaymentMethod.CREDIT_CARD)

  if (creditCardGateway === paymentGateway.PAYMENT_GATEWAY_2C2P) {
    // 2c2p 信用卡支援 recent 和 apple pay
    if (member?.payments?.length) {
      acceptedPaymentMethods.push(PaymentMethod.RECENT)
    }
    // FIXME: 2c2p applepay他們設定問題. 我們暫時隱藏
    // if (canMakeApplePay()) {
    //   acceptedPaymentMethods.push(PaymentMethod.APPLE_PAY)
    // }
  }

  if (isTable ? merchant.setting.enableDineInOctopusOnline : merchant.setting.enableOctopusOnline) {
    acceptedPaymentMethods.push(PaymentMethod.OCTOPUS)
  }
  if (isTable ? merchant.setting.enableDineInWechatPay : merchant.setting.enableWechatPay) {
    acceptedPaymentMethods.push(PaymentMethod.WECHAT_PAY)
  }
  if (isTable ? merchant.setting.enableDineInAliPay : merchant.setting.enableAliPay) {
    // acceptedPaymentMethods.push(PaymentMethod.ALI_PAY)
  }
  if (platform !== 'web') {
    if (isTable ? merchant.setting.enableDineInFps : merchant.setting.enableFps) {
      acceptedPaymentMethods.push(PaymentMethod.FPS)
    }
  }

  return acceptedPaymentMethods
}
